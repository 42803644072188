dmx.Component("geolocation", {

  initialData: {
    support: !!navigator.geolocation,
    status: navigator.geolocation
      ? "Geolocation is not supported by this browser."
      : "Locating...",
    timestamp: null,
    coords: null, // { latitude, longitude, altitude, accuracy, altitudeAccuracy, heading, speed }
  },

  attributes: {
    tracking: {
      type: Boolean,
      default: false,
    },

    enableHighAccuracy: {
      type: Boolean,
      default: false,
    },

    maxAge: {
      type: Number,
      default: 30000,
    },

    timeout: {
      type: Number,
      default: 27000,
    },
  },

  methods: {
    getCurrentPosition () {
      this._getCurrentPosition();
    },

    watchPosition () {
      this._watchPosition();
    },

    clearWatch () {
      this._clearWatch();
    },
  },

  events: {
    success: Event,
    error: Event,
    permissiondenied: Event,
    unavailable: Event,
    timeout: Event,
  },

  errorCodes: {
    1: "permissiondenied",
    2: "unavailable",
    3: "timeout",
  },

  render: false,

  init (node) {
    this._successHandler = this._successHandler.bind(this);
    this._errorHandler = this._errorHandler.bind(this);

    if (this.props.tracking) {
      this._watchPosition();
    } else {
      this._getCurrentPosition();
    }
  },

  performUpdate (updatedProps) {
    if (!this.data.supprt) return;

    if (updatedProps.has("tracking")) {
      if (this.props.tracking) {
        this._watchPosition();
      } else {
        this._clearWatch();
      }
    }
  },

  _getCurrentPosition () {
    if (!this.data.support) return;

    navigator.geolocation.getCurrentPosition(
      this._successHandler,
      this._errorHandler,
      {
        enableHighAccuracy: this.props.enableHighAccuracy,
        timeout: this.props.timeout,
        maximumAge: this.props.maxAge,
      }
    );
  },

  _watchPosition () {
    if (!this.data.support || this._watching) return;
    
    this._watching = navigator.geolocation.watchPosition(
      this._successHandler,
      this._errorHandler,
      {
        enableHighAccuracy: this.props.enableHighAccuracy,
        timeout: this.props.timeout,
        maximumAge: this.props.maxAge,
      }
    );
  },

  _clearWatch () {
    if (!this.data.support || !this._watching) return;

    navigator.geolocation.clearWatch(this._watching);
    delete this._watching;
  },

  _successHandler (pos) {
    this.set("status", "OK");
    this.set("timestamp", pos.timestamp);
    this.set("coords", {
      latitude: pos.coords.latitude,
      longitude: pos.coords.longitude,
      altitude: pos.coords.altitude,
      accuracy: pos.coords.accuracy,
      altitudeAccuracy: pos.coords.altitudeAccuracy,
      heading: pos.coords.heading,
      speed: pos.coords.speed,
    });

    dmx.nextTick(() => this.dispatchEvent('success'));
  },

  _errorHandler (err) {
    this.set(
      "status",
      err.message || this.errorCodes[err.code] || "Unknown Error."
    );

    dmx.nextTick(() => this.dispatchEvent(this.errorCodes[err.code] || "error"));
  }

});
